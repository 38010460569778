import { SnackState } from "./types";
import { RootState } from "@/store/types";
import { Module } from "vuex";

import mutations from "./mutation";
import getters from "./getters";

const state: SnackState = {
  show: false,
  message: "",
  color: "info",
  timeout: -1
};

const actions = {};

export const snack: Module<SnackState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
