



























import { Component, Vue } from "vue-property-decorator";
import { Cv } from "../../models/cv";
import MoreBadges from "@/components/ui/MoreBadges.vue";

@Component({
  components: { MoreBadges },
})
export default class CvPage extends Vue {
  cv: Cv | null = null;
  async mounted() {
    this.cv = await Cv.get(this.$route.params.id);
  }
}
