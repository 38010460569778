

















import { Component, Vue } from "vue-property-decorator";
@Component
export default class Accordion extends Vue {
  state = false;
}
