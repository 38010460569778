import { Factory, Model, hasMany, Response } from "miragejs";
import { AppServer } from "./types";
import faker from "faker";

const skils = [
  "Разработка",
  "Python",
  "PHP",
  "JavaScript",
  "Vue.js",
  "React",
  "AngularJS",
  "Java",
  "Git"
];

export const VacancyFactory = Factory.extend({
  skils() {
    return faker.random.arrayElements(skils);
  },
  name() {
    return faker.name.jobType();
  }
});

export const VacancyModel = Model.extend({});

export const useVacancy = (server: AppServer) => {
  server.get("/vacancy", schema => {
    return schema.all("vacancy").models;
  });

  server.post("/vacancy", (schema, reques) => {
    const data = JSON.parse(reques.requestBody)
    server.create("vacancy", data);
    return new Response(201);
  });

  server.get("/vacancy/:id", (schema, request) => {
    const cv = schema.find("vacancy", request.params.id);
    if (!cv) {
      return new Response(404, {});
    }
    return { ...cv.attrs };
  });
};
