import { axiosInstance } from "./axios";

interface User {
  first_name: string;
  last_name: string;
  avatar: string;
}

interface MessageResponse {
  id: string;
  message: string;
  time: string;
  user: User | null;
  cv?: any;
}

export class Message {
  id: string | null = null;
  message: string;
  time: string;
  user: User | null;
  cv: any;

  constructor(data: MessageResponse) {
    this.id = data.id;
    this.message = data.message;
    this.time = data.time;
    this.user = data.user;
    this.cv = data.cv;
  }

  static async getList() {
    const response = await axiosInstance.get("/message");

    return response.data.map((item: MessageResponse) => {
      return new this(item);
    });
  }
}
