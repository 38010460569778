










import { Component, Vue } from "vue-property-decorator";
import { axiosInstance } from "./models/axios";
import SnackBar from "./components/ui/SnackBar.vue";
import { Getter, Mutation } from "vuex-class";
import TopHeader from "@/block/TopHeader.vue";

@Component({
  components: {
    TopHeader,
    SnackBar,
  },
})
export default class App extends Vue {}
