




















import { SnackState } from "@/store/modules/snack/types";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Mutation, Getter } from "vuex-class";

@Component
export default class SnackBar extends Vue {
  @Getter("snack", { namespace: "snack" }) readonly snack!: SnackState;
  @Mutation("hideMessage", { namespace: "snack" }) hideMessage!: Function;

  timeout = 0;
  @Watch("snack.timeout")
  watchTimeout(value: number) {
    clearTimeout(this.timeout);
    if (value > 0) {
      this.timeout = setTimeout(() => {
        this.hideMessage();
      }, value);
    }
    return false;
  }

  get indeterminate() {
    return this.snack.timeout < 0;
  }
}
