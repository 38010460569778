import { AppServer } from "./types";
import { Response } from "miragejs";

export const useAuth = (server: AppServer) => {
  server.post("/login", (schema, request) => {
    const data = JSON.parse(request.requestBody);
    if (data.password == 123) {
      return new Response(
        201,
        {},
        {
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
        }
      );
    }
    return new Response(400, {}, {});
  });

  server.post("/login/verify", () => {
    return {};
  });

  server.put("/login/password", () => {
    return {};
  });
};
