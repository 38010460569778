





















import { Component, Vue } from "vue-property-decorator";
import { Cv } from "../../models/cv";
import { Message } from "../../models/message";

@Component
export default class VacancyList extends Vue {
  messages: Array<Message> = [];

  async mounted() {
    this.messages = await Message.getList();
  }
}
