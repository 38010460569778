import { Factory, Model, hasMany, Response } from "miragejs";
import { AppServer } from "./types";
import faker from "faker";

export const UserFactory = Factory.extend({
  first_name() {
    return faker.name.firstName();
  },
  last_name() {
    return faker.name.lastName();
  },
  avatar(i: number) {
    return `https://i.pravatar.cc/128?img=${i}`;
  }
});

export const UserModel = Model.extend({
  messages: hasMany("message")
});
