





















































import { Component, Vue } from "vue-property-decorator";
import { Cv } from "../../models/cv";
import Accordion from "@/components/ui/Accordion.vue";
import VacancyItem from "./VacancyItem.vue";
import { Vacancy } from "../../models/vacancy";
import SimpleButton from "@/components/ui/SimpleButton.vue";

@Component({
  components: {
    Accordion,
    VacancyItem,
    SimpleButton,
  },
})
export default class VacancyList extends Vue {
  vacancys: Array<Vacancy> = [];

  get filters() {
    return [
      {
        title: "Активные",
        count: this.vacancys.length,
        items: [
          { title: "Активные", count: this.vacancys.length },
          { title: "Закрытые", count: "2" },
        ],
      },
      {
        title: "Все отделы",
        count: "2",
        items: [
          { title: "Разработка", count: "2" },
          { title: "Все отделы", count: "2" },
        ],
      },
      {
        title: "Все технологии",
        count: "2",
        items: [
          { title: "JavaScript", count: "2" },
          { title: "Python", count: "2" },
          { title: "Vue.js", count: "2" },
          { title: "AngularJS", count: "2" },
          { title: "Все технологии", count: "2" },
        ],
      },
    ];
  }

  addNew() {
    this.$router.push({
      name: "vacancy.new",
    });
  }

  async mounted() {
    this.vacancys = await Vacancy.getList();
  }
}
