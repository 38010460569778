
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MoreBadges extends Vue {
  show = false;
  @Prop({ required: true }) badges!: Array<any>;
  @Prop({ default: 2 }) readonly maxLength!: number;

  get moreCount() {
    return this.badges.length - this.maxLength;
  }
  get needMore() {
    return this.badges.length > this.maxLength && !this.show;
  }

  get badgesList() {
    if (this.show) {
      return this.badges;
    }
    return this.badges.slice(0, this.maxLength);
  }
}
