















import { Component, Vue, Prop } from "vue-property-decorator";
import MoreBadges from "@/components/ui/MoreBadges.vue";
import { Vacancy } from "@/models/vacancy";

@Component({
  components: {
    MoreBadges,
  },
})
export default class VacancyItem extends Vue {
  @Prop({ required: true }) readonly vacancy!: Vacancy;

  getRandomInt() {
    return Math.floor(Math.random() * Math.floor(5));
  }
}
