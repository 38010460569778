import { RootState } from "@/store/types";
import { GetterTree } from "vuex";
import { SnackState } from "./types";
const getters: GetterTree<SnackState, RootState> = {
  snack: state => {
    return {
      show: state.show,
      message: state.message,
      color: state.color,
      timeout: state.timeout
    };
  }
};

export default getters;
