//import config from "@/config";
import store from "@/store";
import axios from "axios";

const axiosInstance = axios.create({
  headers: {
    Authorization: {
      toString() {
        return store.getters.JWT;
      }
    }
  },
  baseURL: "https://hr-api.midvikus.com/api/"
});

export { axiosInstance };
