import { Factory, Model, hasMany, Response } from "miragejs";
import { AppServer } from "./types";
import faker from "faker";

const skils = [
  "Разработка",
  "Python",
  "PHP",
  "JavaScript",
  "Vue.js",
  "React",
  "AngularJS",
  "Java",
  "Git",
]

export const CvFactory = Factory.extend({
  first_name() {
    return faker.name.firstName();
  },
  last_name() {
    return faker.name.lastName();
  },
  avatar(i: number) {
    return `https://i.pravatar.cc/300?img=${i}`;
  },
  skils() {
    return faker.random.arrayElements(skils);
  }
});

export const CvModel = Model.extend({
  messages: hasMany("message")
});

export const useCv = (server: AppServer) => {
  server.get("/resume", schema => {
    return schema.all("cv").models;
  });

  server.get("/resume/:id", (schema, request) => {
    const cv = schema.find("cv", request.params.id);
    if (!cv) {
      return new Response(404, {});
    }
    return { ...cv.attrs, messages: cv.messages.models };
  });
};
