import { createServer, Model } from "miragejs";
import { useAuth } from "./auth";
import { CvModel, CvFactory, useCv } from "./cv";
import { MessageFactory, MessageModel, useMessage } from './message';
import { UserModel, UserFactory } from './user';
import { VacancyModel, VacancyFactory, useVacancy } from './vacancy';

export function makeServer({ environment = "development" } = {}) {
  const server = createServer({
    environment,

    models: {
      cv: CvModel,
      message: MessageModel,
      user: UserModel,
      vacancy: VacancyModel
    },

    factories: {
      cv: CvFactory,
      message: MessageFactory,
      user: UserFactory,
      vacancy: VacancyFactory
    },

    seeds(server) {
      const users = server.createList("user", 2);
      let count = 0;
      server.createList("vacancy", 3);
      server.createList("cv", 5).forEach(cv => {
        return server.createList("message", 5, {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          cv: cv,
          user: () => {
            count++;
            return users[count % 3] || null
          }
        });
      });
      /*
      const schedules = server.createList("schedule", 5);
      server
        .createList("system", 5, {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          schedule: (i: number) => {
            return schedules[i];
          }
        })
        .forEach(system => {
          server.createList("zone", 1, { system }).forEach(zone => {
            server.createList("device", 3, { zone, system });
          });
        });
        */
    },

    routes() {
      useAuth(this);
      this.urlPrefix = 'https://hr-api.midvikus.com'
      
      this.namespace = "/api/";
      useCv(this);
      useMessage(this);
      useVacancy(this);
      this.passthrough("https://hr-api.midvikus.com/api/**");
    }
  });

  return server;
}
