import Vue from "vue";
import store from "@/store";

import VueRouter, { Route, RouteConfig, NavigationGuardNext } from "vue-router";
import Home from "../views/Home.vue";
import VacancyList from "@/views/Vacancy/VacancyList.vue";
import VacancyNew from "@/views/Vacancy/VacancyNew.vue";
import CvPage from "@/views/Cv/CvPage.vue";
import CvList from "@/views/Cv/CvList.vue";
import MessageList from "@/views/Message/MessageList.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/vacancy",
    name: "vacancy.list",
    component: VacancyList
  },
  {
    path: "/vacancy/new",
    name: "vacancy.new",
    component: VacancyNew
  },
  {
    path: "/cv/",
    name: "cv.list",
    component: CvList
  },
  {
    path: "/cv/:id",
    name: "cv.detail",
    component: CvPage
  },
  {
    path: "/message",
    name: "message.list",
    component: MessageList
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve((to: Route, from: Route, next: NavigationGuardNext) => {
  if (
    store.getters["auth/isAuth"] == false &&
    to.name !== "login" &&
    to.name !== "register"
  ) {
    next({ name: "login" });
  }
  next();
});

export default router;
