import Vue from "vue";
import App from "./App.vue";
import { makeServer } from "./mock/server";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import "@/assets/scss/app.scss";

Vue.config.productionTip = false;

//if (process.env.NODE_ENV === "development") {
  makeServer();
//}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
