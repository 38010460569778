var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"8","offset":"1"}},[_c('h1',{staticClass:"page-header"},[_vm._v(" Создать вакансию ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"left-navigation"},[_c('div',{staticClass:"left-navigation-title"},[_vm._v(" Требования "),_c('span',{staticClass:"count"},[_vm._v("0")])]),_c('div',{staticClass:"left-navigation-title",class:{
            active: _vm.page == 'skils',
          },on:{"click":function($event){_vm.page = 'skils'}}},[_vm._v(" Задачи "),_c('span',{staticClass:"count"},[_vm._v(_vm._s(this.select.length))])]),_c('div',{staticClass:"left-navigation-title"},[_vm._v(" Мы предлагаем "),_c('span',{staticClass:"count"},[_vm._v("0")])]),_c('div',{staticClass:"left-navigation-title",class:{
            active: _vm.page == 'show',
          },on:{"click":function($event){_vm.page = 'show'}}},[_vm._v(" Предпросмотр ")])])]),_c('v-col',{attrs:{"cols":"8","offset":"1"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.page == 'skils'),expression:"page == 'skils'"}]},[_c('v-autocomplete',{attrs:{"append-icon":"mdi-search-web","items":_vm.jobsList,"return-object":"","item-value":"title","item-text":"title"},model:{value:(_vm.selectJob),callback:function ($$v) {_vm.selectJob=$$v},expression:"selectJob"}}),_c('div',{staticClass:"toogle-buttons-list"},_vm._l((_vm.skils),function(skil){return _c('span',{key:skil,staticClass:"toogle-button",class:{ active: _vm.select.indexOf(skil) >= 0 },on:{"click":function($event){return _vm.selectSkil(skil)}}},[_vm._v(_vm._s(skil))])}),0)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.page == 'show'),expression:"page == 'show'"}],staticClass:"create-content"},[_c('h3',[_vm._v("Чем предстоит заниматься:")]),_c('ul',[_c('li',[_c('v-text-field',{attrs:{"value":"Введите задачу","hide-details":""}}),_c('simple-button',{staticClass:"mt-3 grey"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")])],1)],1)]),_c('h3',[_vm._v("Ключевые требования:")]),_c('ul',[(_vm.select.length == 0)?_c('li',[_vm._v("Список пуст")]):_vm._e(),_vm._l((_vm.select),function(skill,index){return _c('li',{key:("row-skill-" + index)},[_c('v-text-field',{attrs:{"hide-details":""},model:{value:(_vm.select[index]),callback:function ($$v) {_vm.$set(_vm.select, index, $$v)},expression:"select[index]"}})],1)}),_c('simple-button',{staticClass:"mt-3 grey",on:{"click":function () {
                this$1.select.push('Требование');
              }}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")])],1)],2),_c('h3',[_vm._v("Мы предлагаем:")]),_c('ul',[_c('li',[_c('v-text-field',{attrs:{"value":"Введите преимущество","hide-details":""}})],1),_c('simple-button',{staticClass:"mt-3 grey"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")])],1)],1),_c('simple-button',{staticClass:"mt-4",on:{"click":_vm.saveVacancy}},[_vm._v("Создать вакансию")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }