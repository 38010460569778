







































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import { Vacancy } from "@/models/vacancy";

@Component({
  components: {
    SimpleButton,
  },
})
export default class VacancyNew extends Vue {
  page = "skils";
  vacancy: Vacancy | null = null;
  skils = [];

  selectJob = {};

  @Watch("selectJob")
  onChange(value: any) {
    if (this.vacancy) {
      this.vacancy.name = value.title;
    }
    this.skils = value.skils;
  }

  jobsList = [
    {
      title: "Front-end",
      skils: ["Vue.js", "JavaScript", "AngularJS", "Reackt", "Svetle"],
    },
    {
      title: "Back-end",
      skils: ["Python", "PHP", "Django", "Flask", "Laravel"],
    },
  ];

  get select() {
    if (this.vacancy) {
      return this.vacancy.skils;
    }
    return [];
  }

  mounted() {
    this.vacancy = Vacancy.new();
  }

  selectSkil(skill: string) {
    const index = this.select.indexOf(skill);
    if (index >= 0) {
      this.select.splice(index, 1);
    } else {
      this.select.push(skill);
    }
  }

  async saveVacancy() {
    await this.vacancy?.save();
    this.$router.push({
      name: "vacancy.list",
    });
  }
}
