


































































import { Component, Vue } from "vue-property-decorator";
import { Cv } from "../../models/cv";
import Accordion from "@/components/ui/Accordion.vue";
import CvItem from "./CvItem.vue";
import { Vacancy } from "../../models/vacancy";

@Component({
  components: {
    Accordion,
    CvItem,
  },
})
export default class CvList extends Vue {
  cvs: Array<Cv> = [];
  vacancys: Array<Vacancy> = [];
  selectedVacancy = "Не выбрана вакансия";

  filters = [
    {
      title: "Все направления",
      count: "2",
      items: [
        { title: "Front-end", count: "2" },
        { title: "Back-end", count: "2" },
        { title: "Все направления", count: "2" },
      ],
    },
    {
      title: "Все технологии",
      count: "2",
      items: [
        { title: "JavaScript", count: "2" },
        { title: "Python", count: "2" },
        { title: "Vue.js", count: "2" },
        { title: "AngularJS", count: "2" },
        { title: "Все технологии", count: "2" },
      ],
    },
  ];

  async mounted() {
    this.vacancys = await Vacancy.getList();
    this.cvs = await Cv.getList();
  }

  selectVacancy(val: string) {
    this.selectedVacancy = val;
    this.cvs.sort(() => Math.random() - 0.5);
  }
}
