




















import { Component, Prop, Vue } from "vue-property-decorator";
import MoreBadges from "@/components/ui/MoreBadges.vue";
import { Cv } from "@/models/cv";

@Component({
  components: {
    MoreBadges,
  },
})
export default class CvItem extends Vue {
  @Prop({ required: true }) cv!: Cv;
  @Prop({ required: true }) index!: number;

  get rating() {
    return 8 - this.index;
  }

  getRandomInt() {
    return Math.floor(Math.random() * Math.floor(99));
  }
  navigate(id: string) {
    this.$router.push({ name: "cv.detail", params: { id: id } });
  }
}
