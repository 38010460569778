







































import { Component, Vue } from "vue-property-decorator";
import Avatar from "@/components/ui/Avatart.vue";

@Component({
  components: {
    Avatar,
  },
})
export default class TopHeader extends Vue {}
