import { axiosInstance } from "./axios";

interface VacancyResponse {
  id: string | null;
  name: string;
  skils: Array<string>;
}

export class Vacancy {
  id: string | null = null;
  name: string;
  skils: Array<string>;

  constructor(data: VacancyResponse) {
    this.id = data.id;
    this.name = data.name;
    this.skils = data.skils || [];
  }

  static new(){
    return new this({
      id: null,
      name: "",
      skils: []
    })
  }

  static async getList() {
    const response = await axiosInstance.get("/vacancy");

    return response.data.map((item: VacancyResponse) => {
      return new this(item);
    });
  }

  static async get(id: string) {
    const response = await axiosInstance.get(`/vacancy/${id}`);
    return new this(response.data);
  }

  async save() {
    // TODO: переписать на получение статуса 201 - создано
    
    const response = await axiosInstance.request({
      url: `/vacancy/`,
      method: "POST",
      data: {
        name: this.name,
        skils: this.skils
      }
    });
  } 
}
