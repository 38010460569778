import { Factory, Model, belongsTo } from "miragejs";
import { AppServer } from "./types";
import faker from "faker";

export const MessageFactory = Factory.extend({
  message() {
    return faker.random.words(24);
  },
  time() {
    return faker.date.past();
  },
  user() {
    return null;
  }
});

export const MessageModel = Model.extend({
  cv: belongsTo("cv"),
  user: belongsTo("user")
});

export const useMessage = (server: AppServer) => {
  server.get("/message", schema => {
    return schema.all("message").models.map(message => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return { ...message.attrs, user: message.user, cv: message.cv };
    });
  });
};
