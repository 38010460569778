import { MutationTree } from "vuex";
import { SnackState } from "./types";

const mutation: MutationTree<SnackState> = {
  showMessage(state, data) {
    state.show = true;
    state.message = data.message;
    state.color = data.color || "success";
    state.timeout = data.timeout || -1;
  },
  hideMessage(state) {
    state.show = false;
    state.timeout = -1;
  }
};

export default mutation;
