import { axiosInstance } from "./axios";

interface CvResponse {
  id: string;
  first_name: string;
  last_name: string;
  avatar: string;
  messages?: Array<any>;
  skils: Array<string>;
}

export class Cv {
  id: string | null = null;
  first_name: string;
  last_name: string;
  avatar: string;
  messages: Array<any> = [];
  skils: Array<string>;

  constructor(data: CvResponse) {
    this.id = data.id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.avatar = data.avatar;
    this.messages = data.messages || [];
    this.skils = data.skils || [];
  }

  get fullName() {
    return `${this.first_name} ${this.last_name}`;
  }

  static async getList() {
    const response = await axiosInstance.get("/resume");

    return response.data.map((item: CvResponse) => {
      return new this(item);
    });
  }

  static async get(id: string) {
    const response = await axiosInstance.get(`/resume/${id}`);
    return new this(response.data);
  }


}
